import React, { useEffect, useRef, useCallback } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useMissionContext } from "../context/MissionContext";
import { initializeYinYangApp } from "@micheeeeel/yinyangapp";

mapboxgl.accessToken =
  "pk.eyJ1IjoidG9taWNoIiwiYSI6ImNqbTR2em8zbzB0OXozcnA3YTVtbGthYmEifQ.HSUnomleAXPO2kEwm1Stfw"; // Remplacez par votre token Mapbox

const LevelsMap: React.FC = () => {
  const { filteredMissions } = useMissionContext();
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const markersRef = useRef<mapboxgl.Marker[]>([]);

  // Fonction de nettoyage des marqueurs
  const clearMarkers = useCallback(() => {
    markersRef.current.forEach((marker) => marker.remove());
    markersRef.current = [];
  }, []);

  // Fonction pour convertir un élément SVG en URL de type data:image/svg+xml
  const generateSvgDataUrl = (svgElement: SVGElement): string => {
    const svgString = new XMLSerializer().serializeToString(svgElement);
    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString)}`;
  };

  // Fonction pour créer un marqueur
  const createMarker = useCallback((lat: number, lng: number, id: string) => {
    const markerContainer = document.createElement("div");
    markerContainer.className =
      "cursor-pointer bg-cover bg-no-repeat rounded-full flex items-center justify-center";
    const size = 150;
    markerContainer.style.width = `${size}px`;
    markerContainer.style.height = `${size}px`;

    const tempContainer = document.createElement("div");
    tempContainer.id = `temp-svg-container-${id}`;
    document.body.appendChild(tempContainer);

    initializeYinYangApp({
      containerId: tempContainer.id,
      minEdge: size,
      borderAnimationDuration: 15000,
    });

    setTimeout(() => {
      const svgElement = tempContainer.querySelector(
        "svg"
      ) as SVGElement | null;
      if (svgElement) {
        const svgDataUrl = generateSvgDataUrl(svgElement);
        markerContainer.style.backgroundImage = `url('${svgDataUrl}')`;
      }
      document.body.removeChild(tempContainer);
    }, 500);

    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .marker-with-stem::after {
        content: "";
        position: absolute;
        top: ${size}px; /* Placez le bas de la tige directement au bas du conteneur de l'icône. */
        left: 50%; /* déplacer la tige pour centrer son bord gauche sous le marker */
        transform: translateX(-50%); /* déplacer la tige d'1/2 de sa largeur vers la gauche pour bien la centrer par rapport au marker */
        width: 7px; /* Largeur de la tige */
        height: ${
          size / 2
        }px; /* Hauteur de la tige qu'elle point exactement sur l'emplacement de la carte */
        background-color: rgba(0, 0, 0, 0.3); /* 50% d'opacité */
        border-radius:4px; /* Arrondir les bords de la tige */
      }
    `;
    document.head.appendChild(styleElement);
    markerContainer.classList.add("marker-with-stem");

    const marker = new mapboxgl.Marker({
      element: markerContainer,
      rotationAlignment: "horizon",
      pitchAlignment: "horizon",
      offset: [0, -size],
    })
      .setLngLat([lng, lat])
      .addTo(mapRef.current!);

    markersRef.current.push(marker);
  }, []);

  // Fonction pour initialiser la carte
  const initializeMap = useCallback(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/satellite-v9",
        center: [0, 0],
        zoom: 2,
        projection: "globe",
      });

      mapRef.current.on("style.load", () => {
        mapRef.current?.setFog({
          color: "#ffffff",
          "high-color": "#245cdf",
          "horizon-blend": 0.02,
          range: [0.5, 10],
          "space-color": "#0d0d0d",
          "star-intensity": 0.5,
        });
      });

      mapRef.current.addControl(new mapboxgl.NavigationControl());
    }
  }, []);

  // Fonction pour définir les limites de la carte en fonction des missions
  const setMapBounds = useCallback(
    (missions: { lat: number; lng: number }[]) => {
      const bounds = new mapboxgl.LngLatBounds();
      missions.forEach((mission) => {
        bounds.extend([mission.lng, mission.lat]);
      });

      mapRef.current?.fitBounds(bounds, {
        padding: 100,
        maxZoom: 10,
        duration: 2000,
      });
    },
    []
  );

  useEffect(() => {
    initializeMap();
  }, [initializeMap]);

  useEffect(() => {
    if (mapRef.current) {
      const validMissions = filteredMissions.filter(
        (mission) => !Number.isNaN(mission.lat) && !Number.isNaN(mission.lng)
      );

      if (validMissions.length === 0) return;

      setMapBounds(validMissions);
      clearMarkers();

      validMissions.forEach((mission) => {
        createMarker(mission.lat, mission.lng, mission.id);
      });
    }

    return () => {
      clearMarkers();
    };
  }, [filteredMissions, setMapBounds, clearMarkers, createMarker]);

  return (
    <div className="flex justify-center">
      <div ref={mapContainerRef} className="w-1/2 h-screen p-8 " />
    </div>
  );
};

export default LevelsMap;
