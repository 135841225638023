import TitleSection from "../components/titleSection/TitleSection";
import { Outlet } from "react-router-dom";
import MissionList from "../components/MissionList";
import SearchForm from "../components/SearchForm";
import { useMissionContext } from "../context/MissionContext";

const Accueil = () => {
  const { filteredMissions, filterMissions, countries, types } =
    useMissionContext();

  return (
    <div className="max-w-screen-3xl mx-auto pt-20 pb-20 px-4">
      <TitleSection />
      <Outlet />

      <SearchForm
        onFilterChange={filterMissions}
        countries={countries.map((country) => country.name)}
        types={types.map((type) => type.name)}
      />

      <MissionList missions={filteredMissions} />
    </div>
  );
};

export default Accueil;
